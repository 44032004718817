/* eslint-disable prefer-destructuring */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension, no-undef */
import 'babel-polyfill'
import React from 'react'
//
import PageWrapper from '~components/layout/wrappers'
import { gtmInit, gtmPushEvent } from '~utils/gtm'

const defaultLocaleId = process.env.SITE_DEFAULT_LOCALE
const enabledLocales = process.env.SITE_ENABLED_LOCALES.split(',').map((loc) => loc.trim()) || []
const theme = process.env.SITE_THEME || 'default'

/**
 * Implements Gatsby wrapPageElement(params).
 */
export const wrapPageElement = ({ element, props }) => (
  <PageWrapper defaultLocaleId={defaultLocaleId} enabledLocales={enabledLocales} theme={theme} {...props}>
    {element}
  </PageWrapper>
)

export const onClientEntry = () => {
  gtmInit()
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  gtmPushEvent('gatsby-route-change', { location, prevLocation })
}
