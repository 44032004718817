import React from 'react'
import { Helmet } from 'react-helmet'
import { getCurrentLangKey } from 'ptz-i18n'
//
import { locales, I18nProvider } from '~storybook/i18n'

export const SiteDataContext = React.createContext({})
export const LocationContext = React.createContext({})
export const LocationContextProvider = LocationContext.Provider
export const LocationContextConsumer = LocationContext.Consumer

const PageWrapper = ({ children, location, data, defaultLocaleId, enabledLocales, pageContext }) => {
  if (location.pathname === '/offline-plugin-app-shell-fallback/') {
    return null
  }

  const filteredLocales = {}
  const availableLangs = []

  let defaultLocale = locales.nl

  Object.keys(locales)
    .filter((id) => enabledLocales.includes(id))
    .forEach((id) => {
      filteredLocales[id] = locales[id]
      availableLangs.push(locales[id].id)
      if (defaultLocaleId === id) {
        defaultLocale = locales[id]
      }
    })

  const currentLangKey = getCurrentLangKey(availableLangs, pageContext.language || defaultLocaleId, location.pathname)

  const currentLocale =
    Object.keys(filteredLocales)
      .map((id) => filteredLocales[id])
      .find((locale) => locale.id === currentLangKey) ||
    defaultLocale ||
    locales.nl

  const siteData = {
    group: data?.drupal?.group,
    langSlugs: pageContext.langSlugs || {},
    lang: currentLocale?.id
  }

  return (
    <I18nProvider currentLocale={currentLocale} locales={filteredLocales} key={currentLocale.id}>
      <LocationContextProvider value={location}>
        <SiteDataContext.Provider value={siteData}>
          <Helmet
            htmlAttributes={{
              lang: currentLocale.id
            }}
          />
          {children}
        </SiteDataContext.Provider>
      </LocationContextProvider>
    </I18nProvider>
  )
}

export default PageWrapper
